<script setup lang="ts">
import IconButton from "~/components/IconButton.vue";
import CreateOrgModal from "~/features/orgs/CreateOrgModal.vue";
import { useOrgStore } from "~/store/orgs";
import { generateEntityId } from "~/common/types/helpers";
import type { Org } from "~/common/types/types";
const store = useOrgStore();
const isModalOpen = ref(false);

async function submitted(org: Org) {
  await store.addOrg(org);
  isModalOpen.value = false;
}

const defaultOrg = (): Org => ({
  _id: generateEntityId(),
  name: "",
  logoUrl: "",
  headerColor: "",
  websiteUrl: "",
  created: new Date(),
});
</script>

<template>
  <span
    ><IconButton
      @click="isModalOpen = true"
      type="add"
      size="sm"
      color="primary"
      square
      variant="link"
      title="Create New"
      ></IconButton>
    <CreateOrgModal
      v-if="isModalOpen"
      @close="isModalOpen = false"
      @submitted="submitted"
      :org="defaultOrg()"
  /></span>
</template>
