<script lang="ts" setup>
import AppModal from "@/components/AppModal.vue";
import CreateOrgForm from "@/features/orgs/CreateOrgForm.vue";
import type { Org } from "~/common/types/types";

const emit = defineEmits(["close", "submitted"]);

defineProps({
  org: {
    type: Object as PropType<Org>,
    required: true,
  },
});

function submitted(org: Org) {
  emit("submitted", org);
  closed();
}

function closed() {
    emit("close");
}

</script>
<template>
  <AppModal @close="closed">
    <template #title> Create Org </template>
    <template #body>
      <CreateOrgForm @submitted='submitted' :org="org" />
    </template>
  </AppModal>
</template>