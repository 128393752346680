<script setup lang="ts">
import Joi from 'joi'
import type { FormErrorEvent, FormSubmitEvent } from '#ui/types'
import type { Org } from '~/common/types/types';
import { isEntityId } from '../util';

const props = defineProps({
  org: {
    type: Object as PropType<Org>,
    required: true,
  },
});

const schema = Joi.object({
  _id: Joi.custom(isEntityId),
  name: Joi.string().required(),  
  websiteUrl: Joi.string().allow(''),
  headerColor: Joi.string().allow(''),
  logoUrl: Joi.string().allow(''),
  created: Joi.date().required(),
  defaults: Joi.object().allow()
})

const state = reactive(props.org);

const emit = defineEmits(['submitted'])

async function onSubmit () {
  emit('submitted', state);
}

async function onError (event: FormErrorEvent) {
  console.log(event.errors)
}

</script>

<template>
  <UForm :schema="schema" :state="state" class="space-y-4" @submit="onSubmit" @error="onError" >
    <UFormGroup label="Org Name" name="name">
      <UInput v-model="state.name" />
    </UFormGroup>

    <UFormGroup label="Website Url" name="websiteUrl">
      <UInput v-model="state.websiteUrl" />
    </UFormGroup>

    <UFormGroup label="Logo Url" name="logoUrl">
      <UInput v-model="state.logoUrl" />
    </UFormGroup>

    <UFormGroup label="Header Color" name="headerColor">
      <UInput v-model="state.headerColor" />
    </UFormGroup>

    <UButton type="submit" class="mt-3">
      Create Org
    </UButton>
  </UForm>    
</template>